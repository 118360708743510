import React from "react";

interface Props {
	children: React.ReactNode;
	className?: string;
	leading?: string;
	textColor?: string;
	as?: string;
}

const P: React.FC<Props> = ({
	children,
	className,
	leading = "leading-relaxed",
	textColor = "text-gray-900",
	as = "p",
}) => {
	const classes = `${leading} ${textColor} ${className ? className : ""}`;
	return React.createElement(as, { className: classes }, children);
};

export default P;
