// Packages
import React from "react";
import { graphql, Link } from "gatsby";

// Components
import Container from "~components/common/Container";
import Layout from "~components/layouts/Layout";
import H1 from "~components/typography/H1";

// Types
import type { SiteMetadata, GatsbyImagePropsMeta, Location } from "~types/Gatsby";
import { HomeIcon } from "@heroicons/react/outline";
import P from "~components/typography/P";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata,
	};
	seoImage: {
		facebook: GatsbyImagePropsMeta,
		twitter: GatsbyImagePropsMeta,
	};
	lautenschlagerLogo: {
		publicURL: string,
	};
}

export default function Page({ data, location }: { data: DataProps, location: Location }) {
	return (
		<Layout location={location} title="Anfrage gesendet" desc="" noIndex>
			<Container>
				<div className="mb-10 text-center">
					<P className="mb-2" textColor="text-brand">
						Anfrage gesendet
					</P>
					<H1 display className="mb-4">
						Wir melden uns bei Ihnen zeitnah.
					</H1>
				</div>
				<div className="mx-auto max-w-md">
					<P className="mb-4">Weiter geht es hier:</P>
					<ul className="mt-4 divide-y divide-gray-200 border-t border-b border-gray-200 ">
						{[
							{
								name: "Startseite",
								to: "/",
								icon: HomeIcon,
							},
						].map((link) => (
							<li className="relative flex items-start space-x-4 py-6" key={link.name}>
								<link.icon className="h-8 w-8 flex-shrink-0 text-brand" aria-hidden="true" />
								<div className="min-w-0 flex-1">
									<h3 className="text-base font-medium text-gray-900">
										<span className="rounded-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2">
											<Link to={link.to} className="focus:outline-none">
												<span className="absolute inset-0" aria-hidden="true" />
												{link.name}
											</Link>
										</span>
									</h3>
								</div>
								<div className="flex-shrink-0 self-center">
									<svg
										className="h-5 w-5 text-gray-400"
										x-description="Heroicon name: solid/chevron-right"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
										aria-hidden="true"
									>
										<path
											fillRule="evenodd"
											d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
											clipRule="evenodd"
										/>
									</svg>
								</div>
							</li>
						))}
					</ul>
				</div>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
